import React, { useContext, useEffect } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { HistoryProvider } from '../../utils/hooks/History'
import { isBrowser } from '../../utils/helpers/utils'
import { ease1 } from '../../utils/constants/eases'
import {
  AnimationStateContext,
  AnimationStateProvider,
} from '../../utils/hooks/AnimationState'

if (isBrowser) {
  gsap.registerPlugin(ScrollTrigger)
}

const ChildWrapPageElement = ({ children }) => {
  const { setIsReadyForAnimation } = useContext(AnimationStateContext)

  useEffect(() => {
    document.body.classList.add('hide-scrollbar')
    gsap.to('body', {
      opacity: 1,
      duration: 0.3,
      ease: ease1,
      onComplete: () => {
        setIsReadyForAnimation(true)
      },
    })
  }, [setIsReadyForAnimation])

  return <>{children}</>
}

const WrapPageElement = ({ children }) => {
  return (
    <HistoryProvider>
      <AnimationStateProvider>
        <ChildWrapPageElement>{children}</ChildWrapPageElement>
      </AnimationStateProvider>
    </HistoryProvider>
  )
}

export default WrapPageElement
