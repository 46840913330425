import React, { useState, useCallback, useMemo, createContext, useRef } from 'react'
import { sourceScrollStorageKey } from '../../helpers/utils'

export const HistoryContext = createContext({
  backRoute: '',
  navigationType: 'forwards',
  addRouteToHistory: (props) => {},
  removeRouteFromHistory: () => {},
  replaceRouteFromHistory: (props) => {},
})

export const HistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([])
  const [navigationType, setNavigationType] = useState('forwards')

  const refHistory = useRef([])

  const handleSetHistory = useCallback((value) => {
    refHistory.current = value;
    setHistory(value);
  }, [])

  const routeTypes = useMemo(
    () => ({
      root: 'root',
      modal: 'modal',
    }),
    []
  )

  const defaultRoute = useMemo(
    () => ({
      scrollView: 0,
      path: '/',
      name: '',
      type: routeTypes.root,
      isLandingPage: true
    }),
    [routeTypes]
  )

  const backRoute = useMemo(() => {
    if (!history || !history.length) {
      return defaultRoute
    }
    if (history.length === 1) {
      return history[0].type === routeTypes.modal ? defaultRoute : history[0]
    }
    if (history.length > 1) {
      return history[history.length - 2]
    }
  }, [history, routeTypes, defaultRoute])

  const getLastAddedRoute = useCallback((history) =>  history.length > 0 ? history[history.length - 1] : null, [])

  const isNavigatingFromRootToRoot = useCallback((previousType, newType) => previousType === routeTypes.root && newType === previousType, [routeTypes])

  const getIsLandingPage = useCallback((routeType, historyLength) => routeType === routeTypes.root || (routeTypes.modal && historyLength < 2), [routeTypes])
  
  const currentRoute = useMemo(() => getLastAddedRoute(history), [history, getLastAddedRoute])

  const resetScroll = useCallback(() => {
    sessionStorage.setItem(sourceScrollStorageKey, '0')
  }, [])

  const getUpdatedRouteForHistory = useCallback(({ path, scrollView, type, name }) => {
    const history = [...refHistory.current]
    const newRoute = { 
      path, 
      scrollView, 
      type,
      name,
      isLandingPage: getIsLandingPage(type, history.length + 1)
    }

    const previousRoute = getLastAddedRoute(history)
    
    if (previousRoute && previousRoute.path === newRoute.path) { // Is same page
      return {
        history,
        newRoute: null
      }
    }

    if (previousRoute && isNavigatingFromRootToRoot(previousRoute.type, newRoute.type)) {
      resetScroll()
    }

    return {
      history,
      previousRoute,
      newRoute
    }
  }, [resetScroll, getLastAddedRoute, isNavigatingFromRootToRoot, getIsLandingPage])


  const addRouteToHistory = useCallback((params, forwards) => {
    const { history, previousRoute, newRoute } = getUpdatedRouteForHistory(params)
    
    if (!newRoute) {
      return history
    }
    setNavigationType('forwards')
    
    if (previousRoute && previousRoute.type === routeTypes.root && newRoute.type === routeTypes.root) {
      history.splice(0, history.length)
    }
    
    history.push(newRoute)
    handleSetHistory(history)

    return history
  }, [handleSetHistory, getUpdatedRouteForHistory, routeTypes])

  const saveScrollView = useCallback((scrollTop) => {
    if (!refHistory.current.length) {
      return []
    }

    const newHistory = [...refHistory.current]

    if (newHistory.length) { // Save current scrolltop 
      const previousRouteIndex = newHistory.length - 1
      newHistory[previousRouteIndex].scrollView = scrollTop
    }

    handleSetHistory(newHistory)
  }, [handleSetHistory])

  const removeRouteFromHistory = useCallback(() => {
    if (!refHistory.current.length) {
      return []
    }
    const newHistory = [...refHistory.current]
    
    setNavigationType('backwards')
    newHistory.pop()
    
    const parentRoute = getLastAddedRoute(newHistory)
    const scroll = parentRoute ? parentRoute.scrollView : 0
    sessionStorage.setItem(sourceScrollStorageKey, `${scroll}`)

    handleSetHistory(newHistory);
    return newHistory
  }, [handleSetHistory, getLastAddedRoute])

  const replaceRouteFromHistory = useCallback((params) => {
    setHistory((_history) => _history.map((h) => {
      if (h.name !== params.name) {
        return h
      }
      h.path = params.path 
      return h
    }))
  }, []);

  const isLandingPage = useMemo(() => 
  {
    const currentRoute = getLastAddedRoute(history)
    if (!currentRoute) {
      return true
    }    
    return getIsLandingPage(currentRoute.type, history.length)
  }, [history, getLastAddedRoute, getIsLandingPage])

  const context = useMemo(
    () => ({
      addRouteToHistory,
      removeRouteFromHistory,
      replaceRouteFromHistory,
      saveScrollView,
      navigationType,
      isLandingPage,
      currentRoute, 
      backRoute
    }),
    [addRouteToHistory, saveScrollView, removeRouteFromHistory, replaceRouteFromHistory, navigationType, backRoute, isLandingPage, currentRoute]
  )

  return <HistoryContext.Provider value={context}>{children}</HistoryContext.Provider>
}

export const HistoryConsumer = HistoryContext.Consumer
