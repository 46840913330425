import React, { useMemo, createContext, useContext, useCallback, useState } from 'react'
import { HistoryContext } from '../History'

export const AnimationStateContext = createContext({
  setIsReadyForAnimation: () => {},
  isAnimationAllowed: false,
  canPlayAnimation: false
})

export const AnimationStateProvider = ({ children }) => {
  const { currentRoute, navigationType } = useContext(HistoryContext)
  const [isReady, setIsReady] = useState(false)
  const entryPage = useMemo(() => currentRoute && currentRoute.name ? currentRoute.name : '', [currentRoute])

  const setIsReadyForAnimation = useCallback((state) => {
    setIsReady(state)
  }, [])

  const isAnimationAllowed = useMemo(() => navigationType === 'forwards', [navigationType])
  const canPlayAnimation = useMemo(() => isReady && isAnimationAllowed, [isReady, isAnimationAllowed])

  const context = useMemo(() => ({
    setIsReadyForAnimation,
    isReady,
    isAnimationAllowed,
    canPlayAnimation,
    entryPage
  }), [setIsReadyForAnimation, isReady, isAnimationAllowed, canPlayAnimation, entryPage])

  return <AnimationStateContext.Provider value={context}>{children}</AnimationStateContext.Provider>
}

export const AnimationStateConsumer = AnimationStateContext.Consumer
