// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ui-js": () => import("./../../../src/pages/ui.js" /* webpackChunkName: "component---src-pages-ui-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/_about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/_careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-clients-js": () => import("./../../../src/templates/_clients.js" /* webpackChunkName: "component---src-templates-clients-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/_index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-pack-js": () => import("./../../../src/templates/pack.js" /* webpackChunkName: "component---src-templates-pack-js" */),
  "component---src-templates-packages-js": () => import("./../../../src/templates/_packages.js" /* webpackChunkName: "component---src-templates-packages-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/_projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

