export const sourceScrollStorageKey = '@source/scroll'

export const breakpoints = {
  md: 768,
  lg: 1240,
  xl: 1680,
}

export const checkEntryPage = (entryPage, routeName) => {
  if (!entryPage) {
    return true
  }
  return entryPage === routeName
}

export const getPackColor = (field) => {
  switch (field) {
    case 'tech':
      return '#054F59'
    case 'design':
      return '#1342ac'
    default:
      return '#b39267'
  }
}

export const orderArray = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })

  return array
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const getTextColorDependingOnBg = (hex) => {
  if (hex && hex.indexOf('#') === 0) {
    hex = hex && hex.slice(1)
  }
  if (hex && hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  const r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16)

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
}

export const capitalizeFirstLetter = (str) => {
  if (!str || !str.length) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}
export const lowercaseFirstLetter = (str) => {
  if (!str || !str.length) {
    return ''
  }
  return str.charAt(0).toLowerCase() + str.slice(1)
}

// Durstenfeld shuffle, doesn't keep original array
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const getRandomElementsFromArray = (array, nbElements, noArrayCopy) => {
  const arrayToShuffle = noArrayCopy ? array : array.slice()
  return shuffleArray(arrayToShuffle).slice(0, nbElements)
}

export const getConstrastColor = (hexColor) => {
  const rgbColor = hexToRgb(hexColor)
  const luma =
    (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b) / 255
  return luma > 0.5 ? 'light' : 'dark'
}

export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

export const clamp = (x, min = 0, max = 1) =>
  x < min ? min : x > max ? max : x

export const isTouchDevice = () =>
  typeof window !== 'undefined' &&
  'ontouchstart' in document.documentElement &&
  'orientation' in window

export const handleKeyDownEnter = (cb) => (event) => {
  if (event.key) {
    cb(event)
  }
}

export const isBrowser = typeof window !== 'undefined'

export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function () {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const gtagContactConversion = () => {
  window.gtag &&
    window.gtag('event', 'conversion', {
      send_to: 'AW-717602256/EaSmCLW_xqcBEND7ltYC',
    })
  window.sa_event && window.sa_event('click_contact')
}

export const setCalendlyAnalytics = (service) => {
  window.sa_event && window.sa_event(`calendly_${service.split(' ').join('_')}`)
}

export const isLighthouse = () =>
  navigator.userAgent.indexOf('Chrome-Lighthouse') > -1

export const getPosition = (element) => {
  const rect = element.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop =
    window.pageYOffset || docEl.scrollTop || document.body.scrollTop
  const scrollLeft =
    window.pageXOffset || docEl.scrollLeft || document.body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = Math.round(rect.top + scrollTop - clientTop)
  const left = Math.round(rect.left + scrollLeft - clientLeft)

  return {
    left: left,
    top: top,
  }
}

export const addTrailingSlash = (str) =>
  str.substr(-1) !== '/' ? `${str}/` : str
