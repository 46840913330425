import BezierEasing from 'bezier-easing'

export const ease1 = BezierEasing(0, 0.41, 0.46, 1.12);
export const ease2 = BezierEasing(0.6, 0.19, 0.4, 0.8);
export const easeY = BezierEasing(0, 0, 0, 1);
export const easeInQuad = BezierEasing(0.11, 0, 0.5, 0);
export const easeOutCubic = BezierEasing(0.33, 1, 0.68, 1);
export const easeOutBack = BezierEasing(0.34, 1.56, 0.64, 1);
export const easeOutQuad = BezierEasing(0.5, 1, 0.89, 1);
export const easeOutCirc = BezierEasing(0.04, 1.11, 0.75, 1.01)
